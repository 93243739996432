import React, {useEffect, useState, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {getCurrentDateTime} from "../../../utils/utilsDate";
import InfoSpoiler from "../../../components/Forms/common/InfoSpoiler";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {refreshPage, checkValue, navToAdminPanel, checkAuthToken, navToVehiclesPage, checkRequestStatus} from "../../../utils/utilsGeneral";
import {IVehicle} from "../../../redux-data/vehicle/vehicleTypes";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {AutoComplete} from "../../../components/MainPage/AutoComplete/AutoComplete";
import RegCities from "../../../components/Forms/common/RegCities";
import SwitchComponent from "react-switch";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";
import _ from "lodash";

const EditVehiclePage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID, stateNumber, vehicleID} = useParams<{
        id: string;
        externalID: string;
        stateNumber: string;
        vehicleID: string;
    }>();
    const [editVehicleData, setVehicleDataEdited] = useState<IVehicle>({
        engineVolume: 0,
        year: 0,
        vin: "",
        category: "",
        kind: "",
        brand: "Назва - Skoda",
        model: "Octavia A7",
        modelText: "",
        stateNumber: "",
        dontHaveVIN: false,
        lastModified: "",
        autoColor: "",
        isForeignReg: false,
        registrationPlace: {
            id: 0,
            placeCode: 0,
            zone: 0, 
            nameFull: "", 
            country: "",
            name: "",
            nameRus: "", 
            nameFullRus: "", 
            cdbMtibu: false,
            cdbMtibuCode: 0,
            lastModified: ""
        }
    });
    const [vehicleErrorMsg, setVehicleErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const editEngineVolumeInput = useRef<HTMLInputElement>(null);
    const editYearInput = useRef<HTMLInputElement>(null);
    const editVinInput = useRef<HTMLInputElement>(null);
    const editCategoryInput = useRef<HTMLInputElement>(null);
    const editKindInput = useRef<HTMLInputElement>(null);
    const editBrandInput = useRef<HTMLInputElement>(null);
    const editModelInput = useRef<HTMLInputElement>(null);
    const editModelTextInput = useRef<HTMLInputElement>(null);
    const editStateNumberInput = useRef<HTMLInputElement>(null);
    const editAutoColorInput = useRef<HTMLInputElement>(null);
    const regPlaceIDInput = useRef<HTMLInputElement>(null);
    const regPlaceCodeInput = useRef<HTMLInputElement>(null);
    const vehicleRegLocationInput = useRef<HTMLInputElement>(null);
    const [city, setCity] = useState("");
    const [touch, setTouch] = useState(false);
    const [regPlaceID, setRegPlaceID] = useState("");
    const [regPlaceCode, setRegPlaceCode] = useState("");
    const [authInProgress, setAuthInProgress] = useState(false);
    const [editIsForeign, setForeignEdited] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        fetchVehicle();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const fetchVehicle = async () => {
        try {
            const vehicleData = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(stateNumber!.toUpperCase())}/${encodeURIComponent(vehicleID!)}`)
            const regPlaceData = await dbAxiosInstance.get(`/vehicle/reg-location/${encodeURIComponent(vehicleData.data[0].reg_place_id)}/${encodeURIComponent(vehicleData.data[0].reg_place_code)}`);
            const dbVehicleData = vehicleData.data[0];
            const dbRegPlaceData = regPlaceData.data[0];

            if (dbVehicleData) {
                setVehicleDataEdited((prev) => ({...prev, engineVolume: dbVehicleData.engine_volume, year: dbVehicleData.year, vin: dbVehicleData.vin, category: dbVehicleData.category, kind: dbVehicleData.kind, brand: dbVehicleData.brand, 
                                        model: dbVehicleData.model, modelText: dbVehicleData.model_text, stateNumber: dbVehicleData.state_number, dontHaveVIN: dbVehicleData.dont_have_vin, lastModified: dbVehicleData.last_modified, 
                                        autoColor: dbVehicleData.auto_color, isForeignReg: dbVehicleData.is_foreign}));
                setForeignEdited(dbVehicleData.is_foreign);
                setVehicleErrorMsg("");
            } else {
                setVehicleDataEdited((prev) => ({...prev, engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "Назва - Skoda", model: "Octavia A7", modelText: "", stateNumber: "", dontHaveVIN: false, lastModified: "", autoColor: ""}));
                setForeignEdited(false);
                setVehicleErrorMsg("Немає такого транспортного засобу. Ви можете додати ТЗ через панель адміністратора.");
            }

            if (dbVehicleData && dbVehicleData.reg_place_id && dbVehicleData.reg_place_code && dbRegPlaceData) {
                setVehicleDataEdited((prev) => ({...prev, registrationPlace: {
                    id: dbRegPlaceData.id,
                    placeCode: dbRegPlaceData.place_code,
                    zone: dbRegPlaceData.zone, 
                    nameFull: dbRegPlaceData.name_full, 
                    country: dbRegPlaceData.country,
                    name: dbRegPlaceData.name,
                    nameRus: dbRegPlaceData.name_rus,
                    nameFullRus: dbRegPlaceData.full_rus, 
                    cdbMtibu: dbRegPlaceData.cdb_mtibu,
                    cdbMtibuCode: dbRegPlaceData.cdb_mtibu_code,
                    lastModified: dbRegPlaceData.last_modified
                }}));
                setCity(dbRegPlaceData.name_full);
                setRegPlaceID(dbRegPlaceData.id);
                setRegPlaceCode(dbRegPlaceData.place_code);
            } else {
                setVehicleDataEdited((prev) => ({...prev, registrationPlace: {
                    id: 0,
                    placeCode: 0,
                    zone: 0, 
                    nameFull: "", 
                    country: "",
                    name: "",
                    nameRus: "", 
                    nameFullRus: "", 
                    cdbMtibu: false,
                    cdbMtibuCode: 0,
                    lastModified: ""
                }}));
                setCity("");
                setRegPlaceID("");
                setRegPlaceCode("");
            }
        } catch (error: any) {
            setVehicleDataEdited({engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "Назва - Skoda", model: "Octavia A7", modelText: "", stateNumber: "", dontHaveVIN: false, lastModified: "", autoColor: "", isForeignReg: false, 
                                    registrationPlace: {
                                    id: 0,
                                    placeCode: 0,
                                    zone: 0, 
                                    nameFull: "", 
                                    country: "",
                                    name: "",
                                    nameRus: "", 
                                    nameFullRus: "", 
                                    cdbMtibu: false,
                                    cdbMtibuCode: 0,
                                    lastModified: ""
                                    }});
            setCity("");
            setRegPlaceID("");
            setRegPlaceCode("");
            setVehicleErrorMsg("Помилка БД! Не вдалося завантажити дані транспортного засобу.");
        }
    };

    const clearFields = (event) => {
        event.preventDefault();
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
        setVehicleDataEdited({engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "Назва - Skoda", model: "Octavia A7", modelText: "", stateNumber: "", dontHaveVIN: false, lastModified: "", autoColor: "", isForeignReg: false, 
                            registrationPlace: {
                                id: 0,
                                placeCode: 0,
                                zone: 0, 
                                nameFull: "", 
                                country: "",
                                name: "",
                                nameRus: "", 
                                nameFullRus: "", 
                                cdbMtibu: false,
                                cdbMtibuCode: 0,
                                lastModified: ""
                            }});
        setCity("");
        setRegPlaceID("");
        setRegPlaceCode("");
        setForeignEdited(false);

        if (editEngineVolumeInput.current) {
            editEngineVolumeInput.current.value = "";
        }

        if (editYearInput.current) {
            editYearInput.current.value = "";
        }

        if (editVinInput.current) {
            editVinInput.current.value = "";
        }

        if (editCategoryInput.current) {
            editCategoryInput.current.value = "";
        }

        if (editKindInput.current) {
            editKindInput.current.value = "";
        }

        if (editBrandInput.current) {
            editBrandInput.current.value = "";
        }

        if (editModelInput.current) {
            editModelInput.current.value = "";
        }

        if (editModelTextInput.current) {
            editModelTextInput.current.value = "";
        }

        if (editStateNumberInput.current) {
            editStateNumberInput.current.value = "";
        }

        if (editAutoColorInput.current) {
            editAutoColorInput.current.value = "";
        }

        if (regPlaceIDInput.current) {
            regPlaceIDInput.current.value = "";
        }

        if (regPlaceCodeInput.current) {
            regPlaceCodeInput.current.value = "";
        }
    };

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (editVehicleData.engineVolume.toString().trim().length > 0 && editVehicleData.year.toString().trim().length > 0 && editVehicleData.category.trim().length > 0 && editVehicleData.kind.trim().length > 0 && editVehicleData.brand.trim().length > 0 && 
            editVehicleData.model!.trim().length > 0 && editVehicleData.modelText!.trim().length > 0 && editVehicleData.stateNumber.trim().length > 0 && editVehicleData.lastModified.trim().length > 0) {
            try {
                const res = await dbAxiosInstance.put(`/edit-vehicle/${encodeURIComponent(stateNumber!.toUpperCase())}/${encodeURIComponent(vehicleID!)}`, editVehicleData);
                const editStatus = res.status;
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/vehicle/${stateNumber!.toLowerCase()}/${vehicleID}/item/edited`, {
                    state: {
                        isSuccess: checkRequestStatus(editStatus),
                        status: editStatus,
                        successMsg: res.data,
                        errorMsg: ""
                    }
                });
                setVehicleErrorMsg("");
                setSuccessNotificationHidden(false);
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setVehicleErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setVehicleErrorMsg("Будь ласка, заповніть усі поля, щоб оновити транспортний засіб!");
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const editEngineVolumeChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, engineVolume: checkValue(event.target.value)}));
    };

    const editYearChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, year: checkValue(event.target.value)}));
    };

    const editVINChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, vin: checkValue(event.target.value), dontHaveVIN: event.target.value.length <= 0 ? true : false}));
    };

    const editCategoryChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, category: checkValue(event.target.value)}));
    };

    const editKindChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, kind: checkValue(event.target.value)}));
    };

    const editBrandChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, brand: event.target.value, modelText: `${event.target.value} ${editVehicleData.model}`}));
    };

    const editModelChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, model: event.target.value, modelText: `${editVehicleData.brand} ${event.target.value}`}));
    };

    const editStateNumberChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, stateNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const editDontHaveVINChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, dontHaveVIN: event.target.checked}));
    };

    const editAutoColorChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, autoColor: checkValue(event.target.value)}));
    };

    const handleVechileRegPlace = (event, {newValue, method}) => {
        setCity(newValue);
        
        if (newValue !== "") {
            setTouch(true);
        } else {
            setTouch(false);
        }
    };

    const handleVechileRegContentBlur = (event, {newValue, method}) => {
        setTouch(true);
    };

    const setVechicleRegCity = async (event) => {
        const dataSuggestion = event.target.dataset.suggestion;

        if (vehicleRegLocationInput.current && event.target.value.length > 0 && dataSuggestion.length > 0) {
            vehicleRegLocationInput.current.value = event.target.value;
            setCity(vehicleRegLocationInput.current.value);
            setTouch(true);
            const place = await dbAxiosInstance.get(`/reg-place/${encodeURIComponent(dataSuggestion)}`);
            const regPlace = place.data[0];
            setVehicleRegLocation(regPlace);
        } else {
            setCity("");
            setTouch(false);
        }
    };

    const handleRegPlaceSuggestion = (suggestion) => {
        setVehicleRegLocation(suggestion);
    };

    const setVehicleRegLocation = (location) => {
        if (location) {
            setRegPlaceID(location.id);
            setRegPlaceCode(location.place_code);
            setVehicleDataEdited((prev) => ({...prev, registrationPlace: {
                id: location.id,
                placeCode: location.place_code,
                zone: location.zone, 
                nameFull: location.name_full, 
                country: location.country,
                name: location.name,
                nameRus: location.name_rus, 
                nameFullRus: location.name_full_rus, 
                cdbMtibu: location.cdb_mtibu,
                cdbMtibuCode: location.cdb_mtibu_code,
                lastModified: location.last_modified
            }}));
        } else {
            setRegPlaceID("");
            setRegPlaceCode("");
            setVehicleDataEdited((prev) => ({...prev, registrationPlace: {
                id: 0,
                placeCode: 0,
                zone: 0, 
                nameFull: "", 
                country: "",
                name: "",
                nameRus: "", 
                nameFullRus: "", 
                cdbMtibu: false,
                cdbMtibuCode: 0,
                lastModified: ""
            }}));
        }
    };

    const handleEditForeignStateChange = (state) => {
        setForeignEdited(state);
        setVehicleDataEdited((prev) => ({...prev, isForeignReg: state}));
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати транспортний засіб &#8212; {stateNumber?.toUpperCase()}</h1>
                        <div className="edit-dashboard-item-form">
                            <input type="number" name="engineVolume" placeholder="Відредагувати об'єм двигуна - 1598" onChange={editEngineVolumeChange} ref={editEngineVolumeInput} value={editVehicleData.engineVolume} />
                            <input type="number" name="year" placeholder="Відредагувати рік - 2021" onChange={editYearChange} ref={editYearInput} value={editVehicleData.year} />
                            <input type="text" name="vin" placeholder="Відредагувати VIN код - TVBAN5NE7LB018349" onChange={editVINChange} ref={editVinInput} value={editVehicleData.vin} />
                            <input type="text" name="category" placeholder="Відредагувати категорію - B1, B2..." onChange={editCategoryChange} ref={editCategoryInput} value={editVehicleData.category} />
                            <InfoSpoiler isDefaultSpoilerTitle={true} spoilerContentVisibility={false} spoilerTitle="Детальніше про категорію ТЗ" 
                                spoilerContent={
                                    <div className="vehicle-cat-details">
                                        <p className="font-bold">Категорія ТЗ</p>
                                        <p>Допустимі значення:</p>
                                        <ul>
                                            <li><span className="font-bold">A1</span> - мотоцикл / моторолер - до 300 см3</li>
                                            <li><span className="font-bold">A2</span> - мотоцикл / моторолер - більше 300 см3</li>
                                            <li><span className="font-bold">B1</span> - легковий автомобіль - до 1600 см3</li>
                                            <li><span className="font-bold">B2</span> - легковий автомобіль - 1601 - 2000 см3</li>
                                            <li><span className="font-bold">B3</span> - легковий автомобіль - 2001 - 3000</li>
                                            <li><span className="font-bold">B4</span> - легковий автомобіль - більше 3000 см3</li>
                                            <li><span className="font-bold">B5</span> - легковий електромобіль (виключно з силовим електродвигуном, крім гібридних авто)</li>
                                            <li><span className="font-bold">C1</span> - вантажний автомобіль - до 2т</li>
                                            <li><span className="font-bold">C2</span> - вантажний автомобіль - більше 2т</li>
                                            <li><span className="font-bold">D1</span> - автобус - до 20 людей</li>
                                            <li><span className="font-bold">D2</span> - автобус - більше 20 людей</li>
                                            <li><span className="font-bold">E</span> - причіп до вантажного авто</li>
                                            <li><span className="font-bold">F</span> - причіп до легкового авто</li>
                                        </ul>
                                        <hr/>
                                        <CustomTooltip msg="Посилання на сайт EUA відкриється у новій вкладці"><a href="https://api.ewa.ua/#model-AutoTransferBean" target="_blank" rel="noopener noreferrer">Детальніше про категорію ТЗ на сайті EUA</a></CustomTooltip>
                                    </div>
                                }
                            />
                            <input type="text" name="kind" placeholder="Відредагувати тип - CAR, MOTO..." onChange={editKindChange} ref={editKindInput} value={editVehicleData.kind} />
                            <InfoSpoiler isDefaultSpoilerTitle={true} spoilerContentVisibility={false} spoilerTitle="Детальніше про тип ТЗ" 
                                spoilerContent={
                                    <div className="vehicle-kind-details">
                                    <p className="font-bold">Тип автомобіля</p>
                                    <p>Допустимі значення:</p>
                                    <ul>
                                        <li><span className="font-bold">MOTO</span> - мотоцикл</li>
                                        <li><span className="font-bold">CAR</span> - автомобіль</li>
                                        <li><span className="font-bold">PASSENGER</span> - автобус</li>
                                        <li><span className="font-bold">FREIGHT</span> - вантажний автомобіль</li>
                                        <li><span className="font-bold">TRAILER</span> - причіп</li>
                                        <li><span className="font-bold">AGRICULTURAL</span> - сільськогосподарська техніка</li>
                                        <li><span className="font-bold">SPECIAL</span> - спецтранспорт</li>
                                    </ul>
                                    <hr/>
                                    <CustomTooltip msg="Посилання на сайт EUA відкриється у новій вкладці"><a href="https://api.ewa.ua/#model-AutoModel" target="_blank" rel="noopener noreferrer">Детальніше про тип ТЗ на сайті EUA</a></CustomTooltip>
                                    </div>
                                }
                            />
                            <input type="text" name="brand" placeholder="Відредагувати бренд - Skoda" onChange={editBrandChange} ref={editBrandInput} value={editVehicleData.brand} />
                            <input type="text" name="model" placeholder="Відредагувати модель - Octavia A7" onChange={editModelChange} ref={editModelInput} value={editVehicleData.model ? editVehicleData.model : ""} />
                            <input type="text" name="modelText" placeholder="Відредагувати назва - Skoda Octavia A7" ref={editModelTextInput} value={editVehicleData.modelText ? editVehicleData.modelText : ""} readOnly={true} />
                            <input type="text" name="stateNumber" placeholder="Відредагувати державний номер - AА0000AA" onChange={editStateNumberChange} ref={editStateNumberInput} value={editVehicleData.stateNumber} />
                            <div className="form-group form-check">
                                <input name="dontHaveVIN" id="dont-have-vin" type="checkbox" hidden checked={editVehicleData.dontHaveVIN} onChange={editDontHaveVINChange} disabled />
                                <label htmlFor="dont-have-vin">Немає VIN коду</label>
                            </div>
                            <input type="text" name="autoColor" placeholder="Відредагувати колір - БІЛИЙ" onChange={editAutoColorChange} ref={editAutoColorInput} value={editVehicleData.autoColor} />
                            <div className="switch-wrapper">
                                <label className="switch-container">
                                    <SwitchComponent onChange={handleEditForeignStateChange} checked={editIsForeign} onColor="#1BA876" />
                                    <div className="switch-text">Iноземна реєстрація</div>
                                </label>
                            </div>
                            {!editIsForeign &&
                            <>
                                <AutoComplete ref={vehicleRegLocationInput} content={city} handleContent={handleVechileRegPlace} handleContentSuggestion={handleRegPlaceSuggestion} handleContentBlur={handleVechileRegContentBlur} isTouch={touch} />
                                <div className="vehicle-reg-location">
                                    <RegCities handleVechicleRegCity={setVechicleRegCity} />
                                </div>
                                <input type="number" name="regPlaceID" placeholder="Відредагувати ідентифікатор місця реєстрації" ref={regPlaceIDInput} readOnly={true} value={regPlaceID} />
                                <input type="text" name="regPlaceCode" placeholder="Відредагувати код місця реєстрації" ref={regPlaceCodeInput} readOnly={true} value={regPlaceCode} />
                            </>
                            }
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={() => navToVehiclesPage(dashboardNavigate, id, externalID)}>Транспортні засоби</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && vehicleErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{vehicleErrorMsg}</div>}
                  </>
                }
                </div>
            </div>
        </div>
    );
};

export default EditVehiclePage;