import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, Link, useParams} from "react-router-dom";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {checkAuthToken, isMatchCaseSearch, filterRecords, countRecords, findInsuranceLabelByType} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import DataFilter from "../../../utils/DataFilter";
import FilterPagination from "../../../utils/FilterPagination";
import webConfig from "../../../config";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {formatDBDateTime} from "../../../utils/utilsDate";
import InsuranceStep from "../../../components/Forms/common/InsuranceStep";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";

const AnalyticsPage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const [authInProgress, setAuthInProgress] = useState(false);
    const {id, externalID} = useParams<{
        id: string; 
        externalID: string;
    }>();
    const [analytics, setAnalytics] = useState<any[]>([]);
    const [analyticsCount, setAnalyticsCount] = useState(0);
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const [analyticsErrorMsg, setAnalyticsErrorMsg] = useState("");
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [filterCompleted, setFilterCompleted] = useState(false);
    const [processingFilter, setProcessingFilter] = useState(false);
    const [isMatchCaseFilter, setMatchCaseFilter] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    useEffect(() => {
        const fetchAnalytics = async () => {
            try {
                const res = await dbAxiosInstance.get("/admin/analytics");
                const dbAnalyticsData = res.data;

                if (dbAnalyticsData) {
                    if (dbAnalyticsData.length > 0) {
                        setAnalytics(dbAnalyticsData);
                        setFilteredItems(dbAnalyticsData);
                        setAnalyticsErrorMsg("");
                    } else {
                        setAnalytics([]);
                        setFilteredItems([]);
                        setAnalyticsErrorMsg("Немає доданих даних аналітики.");
                    }
                } else {
                    setAnalytics([]);
                    setFilteredItems([]);
                    setAnalyticsErrorMsg("Помилка БД! Не вдалося завантажити дані аналітики.");
                }
            } catch (error: any) {
                setAnalytics([]);
                setFilteredItems([]);
                setAnalyticsErrorMsg("Помилка БД! Не вдалося завантажити дані аналітики.");
            }
        };

        const countAnalytics = async () => {
            try {
                const res = await dbAxiosInstance.get("/admin/count-analytics");
                const dbCountAnalyticsData = res.data;

                if (dbCountAnalyticsData) {
                    if (dbCountAnalyticsData.length > 0) {
                        setAnalyticsCount(dbCountAnalyticsData[0].counter);
                    } else {
                        setAnalyticsCount(0);
                    }
                } else {
                    setAnalyticsCount(0);
                }
            } catch (error: any) {
                setAnalyticsCount(0);
            }
        };

        const checkSearchType = async () => {
            const isOn = await isMatchCaseSearch();
            setMatchCaseFilter(isOn);
        };

        fetchAnalytics();
        countAnalytics();
        checkSearchType();
    }, []);
    
    const handleRecordsOnPage = (records) => {
        setRecordsOnPage(records);
    };

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/admin/analytics-data/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            const allRecords = countRecords(analyticsCount);
            setAnalyticsCount(allRecords);

            if (allRecords === 0) {
                setAnalyticsErrorMsg("Немає доданих даних аналітики.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setAnalyticsErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setAnalytics([]);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Аналітика</h1>
                         <div className="itemsCounter">
                            <p>Даних аналітики: <span className="font-bold">{analyticsCount}</span></p>
                        </div>
                        <DataFilter items={analytics} matchCase={isMatchCaseFilter} placeholderText="Введіть дані аналітики для пошуку..." onFilterCompleted={(state) => setFilterCompleted(state)} onProcessingFilter={(state) => setProcessingFilter(state)} onFilteredItems={(filteredItems) => setFilteredItems(filteredItems)} itemNotFoundText="За Вашим запитом даних аналітики не знайдено!" />
                        <div className="dashboard">
                            {analytics.length > 0 && analyticsCount > 0 ?
                                <div className="analytics-wrapper">
                                    <table className="analytics">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Геолокація</th>
                                                <th>IP клієнта</th>
                                                <th>Крок страхування</th>
                                                <th>Тип страхування</th>
                                                <th>Додано</th>
                                                <th>Управління</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {recordsOnPage.map((analyticsItem, index) =>
                                                <tr key={index}>
                                                    <td>{analyticsItem.id}</td>
                                                    <td><CustomTooltip msg="Натисність, щоб переглянути дані аналітики"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/${analyticsItem.id}/view`}}>{analyticsItem.geolocation}</Link></CustomTooltip></td>
                                                    <td><CustomTooltip msg="Натисність, щоб переглянути дані аналітики"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/${analyticsItem.id}/view`}}>{analyticsItem.customer_ip}</Link></CustomTooltip></td>
                                                    <td><CustomTooltip msg="Натисність, щоб переглянути дані аналітики"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/${analyticsItem.id}/view`}}><InsuranceStep step={analyticsItem.insurance_step} /></Link></CustomTooltip></td>
                                                    <td><CustomTooltip msg="Натисність, щоб переглянути дані аналітики"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/${analyticsItem.id}/view`}}>{findInsuranceLabelByType(analyticsItem.insurance_type)}</Link></CustomTooltip></td>
                                                    <td><CustomTooltip msg="Натисність, щоб переглянути дані аналітики"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/${analyticsItem.id}/view`}}>{formatDBDateTime(analyticsItem.added)}</Link></CustomTooltip></td>
                                                    <td>
                                                    <div className="manage-item-container">
                                                            <CustomTooltip msg="Переглянути дані аналітики">
                                                                <Link className="view-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/${analyticsItem.id}/view`}}>
                                                                    <i className="fa fa-circle-info"></i>
                                                                </Link>
                                                            </CustomTooltip>
                                                            <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => handleItemDelete(analyticsItem.id)}><i className="fa fa-trash"></i></a></CustomTooltip>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <FilterPagination data={filteredItems} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} isDataLoaded={filterCompleted} setDataLoaded={(state) => setFilterCompleted(state)} isFilterProcessing={processingFilter} setFilterState={(state) => setProcessingFilter(state)} />
                                </div>
                              : <div>
                                    <div className="no-dashboard-item">{analyticsErrorMsg}</div>
                                </div>
                            }
                        </div>
                    </div>
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default AnalyticsPage;