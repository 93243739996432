import React from "react";
import {useDispatch} from "react-redux";
import {companyImage, companyImages} from "../../constants/companyImages";
import {IFranchise} from "../../typings/IDataOffers";
import {getDiscountPercentage, getCashback} from "../../redux-data/insurance/insuranceSelector";
import {setPolicyId} from "../../redux-data/insurance/insuranceReducer";

interface IPolicy {
  item: IFranchise;
  onBuy?: Function;
  name: string;
  companyId: number;
}

const PolicyAmount = ({onBuy, item, name, companyId}: IPolicy) => {
  const dispatch = useDispatch();
  const img = companyImages.find((company: companyImage) => company.id === companyId);
  const handleClick = () => {
    if (onBuy) {
        dispatch(setPolicyId(item.franchiseID));
        onBuy(item.id);
    }
  };

  const discountedItem = getDiscountPercentage(item.amount, item.discountedAmount);
  const greenCardCashback = getCashback(item.amount, item.cashbackPercentage);

  return (
    <>
      <div className="search-result_one">
        <div className="search-result_left">
          <figure className="search-result_pic">
          <img src={img ? require(`../../assets/img/logos/${img.key}`).default : "\\img\\base.webp"} alt="" />
          </figure>
          <article className="search-result_info">
            <h4>{name}</h4>
            <p className="search-result_price">Франшиза {item.franchise} грн</p>
            {item.amount > item.discountedAmount ?
              <div className="search-result_price">
                <div className="old-price">Вартість {item.amount.toFixed(2)} ₴</div>
                <div>Знижка {discountedItem.toFixed(0)}%</div>
                <div className="price-item">Вартість {item.discountedAmount.toFixed(2)} ₴</div>
                {item.actionDescription && <div className="policy-item-action-description" dangerouslySetInnerHTML={{__html: item.actionDescription}}></div>}
                {item.discountDescription && <div className="policy-item-discount-description" dangerouslySetInnerHTML={{__html: item.discountDescription}}></div>}
              </div>
              :
              <div className="search-result_price">
                  <div className="price-item">Вартість {item.amount.toFixed(2)} ₴</div>
                  {item.isCashback && item.cashbackPercentage > 0 && <div className="cashback-item">Кешбек {item.cashbackPercentage}% на Вашу карту {greenCardCashback.toFixed(2)} ₴</div>}
              </div>
            }
          </article>
        </div>
        <div className="search-result_right">
          <button
            type="submit"
            className="policy-btn btn-link btn-white-border active"
            onClick={handleClick}
          >Купити</button>
        </div>
      </div>
    </>
  )
};

export default PolicyAmount;
