import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {formatDBDateTime} from "../../../utils/utilsDate";
import {getCurrentUser, getCurrentUserInfo} from "../../../redux-data/user/userReducer";
import {useSelector} from "react-redux";
import {refreshPage, navToAdminPanel, checkAuthToken} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {getEagentToken} from "../../../redux-data/misc/miscReducer";

const ViewVehiclePage = (props) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const dashboardNavigate = useNavigate();
    const {id, externalID, stateNumber, vehicleID} = useParams<{
        id: string;
        externalID: string;
        stateNumber: string;
        vehicleID: string;
    }>();
    const [vehicleData, setVehicleData] = useState<any[]>([]);
    const [vehicleErrorMsg, setVehicleErrorMsg] = useState("");
    const [vehicleRegLocation, setVehicleRegLocation] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [authInProgress, setAuthInProgress] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);
    
    useEffect(() => {
        const fetchVehicle = async () => {
            try {
                const vehicleData = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(stateNumber!.toUpperCase())}/${encodeURIComponent(vehicleID!)}`);
                const regPlaceData = await dbAxiosInstance.get(`/vehicle/reg-location/${encodeURIComponent(vehicleData.data[0].reg_place_id)}/${encodeURIComponent(vehicleData.data[0].reg_place_code)}`);
                const dbVehicleData = vehicleData.data;
                const dbRegPlaceData = regPlaceData.data;

                if (dbVehicleData) {
                    if (dbVehicleData.length > 0) {
                        setVehicleData(dbVehicleData);
                        setVehicleErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setVehicleData([]);
                        setVehicleErrorMsg("Немає такого транспортного засобу. Натисніть на кнопку \"Додати\", щоб додати ТЗ.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setVehicleData([]);
                    setVehicleErrorMsg("Помилка БД! Не вдалося завантажити транспортний засіб.");
                    setErrorNotificationHidden(false);
                }

                if (dbRegPlaceData) {
                    if (dbRegPlaceData.length > 0) {
                        setVehicleRegLocation(dbRegPlaceData[0].name_full);
                    } else {
                        setVehicleRegLocation("");
                    }
                } else {
                    setVehicleRegLocation("");
                }
            } catch (error: any) {
                setVehicleData([]);
                setVehicleRegLocation("");
                setVehicleErrorMsg("Помилка БД! Не вдалося завантажити транспортний засіб.");
                setErrorNotificationHidden(false);
            }
        };
        
        fetchVehicle();
    }, []);

    const addVehicle = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/add-vehicle`);
    };

    const editVehicle = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/edit-vehicle/${stateNumber}/${vehicleID}`);
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const navToVehiclesPage = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/vehicles`);
    };

    const deleteVehicle = async (id) => {
        try {
            await dbAxiosInstance.delete(`/vehicle/${encodeURIComponent(id)}`);
            dashboardNavigate(`/admin/dashboard/${id}/${externalID}/vehicles`);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setVehicleErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setVehicleData([]);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Транспортний засіб &#8212; {stateNumber?.toUpperCase()}</h1>
                        {vehicleData.length > 0 && vehicleData.map((vehicle) => 
                            <div>
                                <p className="item-title">Об'єм двигуна: <span className="font-bold">{vehicle.engine_volume > 0 ? vehicle.engine_volume : 0}</span></p>
                                <p className="item-title">Рік: <span className="font-bold">{vehicle.year > 0 ? vehicle.year : 0}</span></p>
                                <p className="item-title">VIN код: <span className="font-bold">{vehicle.vin.length > 0 ? vehicle.vin : "Невідомо"}</span></p>
                                <p className="item-title">Категорія: <span className="font-bold">{vehicle.category.length > 0 ? vehicle.category : "Невідомо"}</span></p>
                                <p className="item-title">Тип: <span className="font-bold">{vehicle.kind.length > 0 ? vehicle.kind : "Невідомо"}</span></p>
                                <p className="item-title">Бренд: <span className="font-bold">{vehicle.brand.length > 0 ? vehicle.brand : "Невідомо"}</span></p>
                                <p className="item-title">Модель: <span className="font-bold">{vehicle.model.length > 0 ? vehicle.model : "Невідомо"}</span></p>
                                <p className="item-title">Назва: <span className="font-bold">{vehicle.model_text.length > 0 ? vehicle.model_text : "Невідомо"}</span></p>
                                <p className="item-title">Державний номер: <span className="font-bold">{vehicle.state_number}</span></p>
                                <p className="item-title">Додано/оновлено: <span className="font-bold">{formatDBDateTime(vehicle.last_modified)}</span></p>
                                <p className="item-title">Немає VIN коду: <span className="font-bold">{vehicle.dont_have_vin ? <span className="dont-have-vin-code">Так</span> : <span className="vin-code-available">Ні</span>}</span></p>
                                <p className="item-title">Колір: <span className="font-bold">{vehicle.auto_color.length > 0 ? vehicle.auto_color : "Невідомо"}</span></p>
                                <p className="item-title">Іноземна реєстрація: <span className="font-bold">{vehicle.is_foreign ? <span className="is-foreign-reg">Так</span> : <span className="is-state-reg">Ні</span>}</span></p>
                                {!vehicle.is_foreign &&
                                  <>
                                    <p className="item-title">Місце реєстрації: <span className="font-bold">{vehicleRegLocation ? vehicleRegLocation : "Невідомо"}</span></p>
                                    <p className="item-title">Ідентифікатор місця реєстрації: <span className="font-bold">{vehicle.reg_place_id > 0 ? vehicle.reg_place_id : "Невідомо"}</span></p>
                                    <p className="item-title">Код місця реєстрації: <span className="font-bold">{vehicle.reg_place_code > 0 ? vehicle.reg_place_code : "Невідомо"}</span></p>
                                  </>
                                }
                            </div>
                        )}
                        <button type="button" className="admin-btn" onClick={addVehicle}><i className="fa fa-plus notification-icon"></i>Додати</button>
                        <button type="submit" className="admin-btn" onClick={editVehicle}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => deleteVehicle(vehicleID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={navToVehiclesPage}>Транспортні засоби</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && vehicleErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{vehicleErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default ViewVehiclePage;