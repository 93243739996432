import {IDataOsagoPost} from "../typings/IDataOsagoPost";
import {instance} from "../core/configEuaAxios";
import {ICompanyInsurance, IDataOffer, IDataNumberOffer, IGreencardCashback} from "../typings/IDataOffers";
import {AxiosResponse} from "axios";
import {dbAxiosInstance} from "../core/configDBAxios";
import {setError} from "../redux-data/offer/offerReducer";
import {parseEUAErrorMessage} from "../utils/utilsGeneral";

const getToken = async () => {
  return await instance.post("token", {
    apiKey: "xGvoHaPSiEFGxYxOA9I9Z9aufVyjpqWWkZuAUK0esFCO3Vshf9dv2UQD8Ese"
  });
};

const getCurrentUser = async () => {
  const res = await instance.get("user/current");
  return res.data;
};

const getLoginUser = async (body: any) => {
  const res = await instance.post("user/login", body);
  return res.data;
};

const getUserInfo = async (id: number) => {
  const res = await instance.get(`user/${id}`);
  return res.data;
};

const getVehicle = async (regNumber: string) => {
  return await instance.get(`auto/mtibu/number?query=${regNumber}`);
};

const getCityCode = async (city: string) => {
  const res = await instance.get(`place?country=UA&query=${city}`);
  // if(res.data.httpStatusCode !== 200) {
  //   throw res.data;
  // }
  return res.data;
};

const getCities = async () => {
  const res = await instance.get("place/full?country=UA");
  return res.data;
};

const getOffers = async (type: "epolicy" | "greencard", data: IDataOffer) => {
  const greencardCompanies: IGreencardCashback[] = [];
  const greencardData: IGreencardCashback = {
    id: 0,
    name: "",
    companyID: 0,
    productCode: 0,
    isCashbackEnabled: false,
    isHidden: false,
    cashbackPercentageValue: 0
  };

  if (type === "greencard") {
      const greencardCompaniesRes = await dbAxiosInstance.get("/greencard/companies");

      greencardCompaniesRes.data.map((greencardCompany) => {
         greencardCompanies.push({
          id: greencardCompany.id,
          name: greencardCompany.name,
          companyID: greencardCompany.company_id,
          productCode: greencardCompany.product_code,
          isCashbackEnabled: greencardCompany.is_cashback_enabled,
          isHidden: greencardCompany.is_hidden,
          cashbackPercentageValue: greencardCompany.cashback_percentage
        });
      });
  }

  const res = await instance.get(`tariff/choose/${type}`, {
    params: data
  });
  const model: any = res.data;
  const results: ICompanyInsurance[] = [];

  //todo delete fake
  let min: any = null;
  let max = 0;

  model.forEach(async (item: any, index: number) => {
    if (item.payment > 0 && !item.crossSell) {
        const company = results.find((result) => result.productId === item.tariff.insurer.id);
        const amount = +item.payment;
        const franchise = item.tariff.franchise ? +item.tariff.franchise : 0;
        const franchiseID = item.tariff.id ? +item.tariff.id : 0;

        if (min === null) {
            min = amount;
        }

        min = Math.min(min, amount);
        max = Math.max(max, amount);

        const isItemCashback = greencardCompanies.find((greencardCompany: IGreencardCashback) => greencardCompany.productCode === Number(item.tariff.insurer.code))?.isCashbackEnabled;
        const isItemHidden = greencardCompanies.find((greencardCompany: IGreencardCashback) => greencardCompany.productCode === Number(item.tariff.insurer.code))?.isHidden;
        const itemCashbackPercentage = greencardCompanies.find((greencardCompany: IGreencardCashback) => greencardCompany.productCode === Number(item.tariff.insurer.code))?.cashbackPercentageValue;

        if (company) {
            company.data.push({
              amount,
              franchise,
              franchiseID,
              id: company.data.length,
              discountedAmount: item.discountedPayment,
              actionDescription: item.actionDescription,
              discountDescription: item.discountDescription,
              isCashback: isItemCashback ? isItemCashback : false,
              isHidden: isItemHidden ? isItemHidden : false,
              cashbackPercentage: itemCashbackPercentage ? itemCashbackPercentage : 0,
              productCode: item.tariff.insurer.code,
              crossSell: item.crossSell,
              onlyOwnInsurer: item.onlyOwnInsurer
            });
        } else {
            results.push({
              productId: item.tariff.insurer.id,
              contractorId: item.tariff.id,
              id: results.length,
              companyId: item.tariff.insurer.id,
              name: item.tariff.insurer.name,
              company: {
                id: item.tariff.company.id,
                type: item.tariff.company.type,
              },
              insurer: {
                id: item.tariff.insurer.id,
                type: item.tariff.insurer.type,
              },
              periods: item.tariff.periods && item.tariff.periods.map(item => item.period),
              data: [
                {
                  id: 0,
                  amount,
                  franchise,
                  franchiseID,
                  discountedAmount: item.discountedPayment,
                  actionDescription: item.actionDescription,
                  discountDescription: item.discountDescription,
                  isCashback: isItemCashback ? isItemCashback : false,
                  isHidden: isItemHidden ? isItemHidden : false,
                  cashbackPercentage: itemCashbackPercentage ? itemCashbackPercentage : 0,
                  productCode: item.tariff.insurer.code,
                  crossSell: item.crossSell,
                  onlyOwnInsurer: item.onlyOwnInsurer
                },
              ],
            });

            if (type === "greencard") {
                greencardData.name = results[index].name;
                greencardData.companyID = results[index].companyId;
                greencardData.productCode = results[index].data[0].productCode;
                greencardData.isCashbackEnabled = results[index].data[0].isCashback;
                greencardData.cashbackPercentageValue = results[index].data[0].cashbackPercentage;
                greencardData.isHidden = results[index].data[0].isHidden;
                await dbAxiosInstance.post("/greencard/add-company", greencardData);
            }
        }
    }
  });

  return results;
};

const getOffersByNumber = async (dispatch: any, data: IDataNumberOffer) => {
  try {
    const res = await instance.get("tariff/choose/policy/statenumber", {
      params: data
    });
    const model: any = res.data;
    const results: ICompanyInsurance[] = [];

    //todo delete fake
    let min: any = null;
    let max = 0;

    model.forEach((item: any, index: number) => {
      if (item.payment > 0 && !item.crossSell) {
          const company = results.find((result) => result.productId === item.tariff.insurer.id);
          const amount = +item.payment;
          const franchise = item.tariff.franchise ? +item.tariff.franchise : 0;
          const franchiseID = item.tariff.id ? +item.tariff.id : 0;

          if (min === null) {
              min = amount;
          }

          min = Math.min(min, amount);
          max = Math.max(max, amount);

          if (company) {
              company.data.push({
                amount,
                franchise,
                franchiseID,
                id: company.data.length,
                discountedAmount: item.discountedPayment,
                actionDescription: item.actionDescription,
                discountDescription: item.discountDescription,
                isCashback: false,
                cashbackPercentage: 0,
                isHidden: false,
                productCode: item.tariff.insurer.code,
                crossSell: item.crossSell,
                onlyOwnInsurer: item.onlyOwnInsurer
              });
          } else {
              results.push({
                productId: item.tariff.insurer.id,
                contractorId: item.tariff.id,
                id: results.length,
                companyId: item.tariff.insurer.id,
                name: item.tariff.insurer.name,
                company: {
                  id: item.tariff.company.id,
                  type: item.tariff.company.type,
                },
                insurer: {
                  id: item.tariff.insurer.id,
                  type: item.tariff.insurer.type,
                },
                periods: item.tariff.periods && item.tariff.periods.map(item => item.period),
                data: [
                  {
                    id: 0,
                    amount,
                    franchise, 
                    franchiseID,
                    discountedAmount: item.discountedPayment,
                    actionDescription: item.actionDescription,
                    discountDescription: item.discountDescription,
                    isCashback: false,
                    cashbackPercentage: 0,
                    isHidden: false,
                    productCode: item.tariff.insurer.code,
                    crossSell: item.crossSell,
                    onlyOwnInsurer: item.onlyOwnInsurer
                  },
                ],
              });
          }
      }
    });

    return results;
  } catch (ex: any) {
    dispatch(setError("Неможлива тарифікація ТЗ по держ. номеру. Прохання здійснити тарифікацію по параметрам"));
    throw new Error("Неможлива тарифікація ТЗ по держ. номеру. Прохання здійснити тарифікацію по параметрам");
  }
};

const createOffer = async (data: IDataOsagoPost) => {
  try {
    const res = await instance.post("contract/save", data);

    if (res.status === 201 || res.status === 200) {
        return {
          success: true,
          id: res.data.id,
        };
    } else {
        const parseData = JSON.parse(res.data.message);
        const first = Object.keys(parseData.errors)[0];
        let message = parseData.errors[first][0];

        return {
          success: false,
          message
        };
    }
  } catch (e: any) {
    console.error(">>> ERROR: ", e);
    const euaErrorMsg = parseEUAErrorMessage(e);

    return {
      success: false,
      message: euaErrorMsg || "Виникла помилка на стороні сервісу страхування. Зверніться до нас за допомогою: <a href=\"mailto:pessorgua@gmail.com\">pessorgua@gmail.com</a>",
    };
  }
};

const getMarkCode = async () => {
  const res = await instance.get("auto_model/makers");
  return res.data || [];
};

const getMarkCodeByName = async (name: string) => {
  if (!name) return [];
  const res = await instance.get("auto_model/makers", {
    params: {
      name
    }
  });

  return res.data || [];
};

const getModelCode = async (brandId: string) => {
  if (!brandId) return [];
  const res = await instance.get("auto_model/models", {
    params: {
      maker: brandId
    }
  });

  return res.data || [];
};

const mapGetModelCode = async (brandId: string) => {
  const res = await getModelCode(brandId);
  return res.map((item: any) => ({
    label: item.name,
    value: item.id,
    mtsbuId: item.mtsbuId
  }));
};

const manageOffer = async (id: string, state: string) => {
  return customFetch(() => instance.post(`contract/${id}/state/${state}`));
};

const sendOPT = async (id: string) => {
  return customFetch(() => instance.get(`contract/${id}/otp/send?customer=true`));
};

const confirmOPT = async ({id, password}: {id: string; password: string}) => {
  return customFetch(() => instance.get(`contract/${id}/otp?customer=${password}`));
};

const customFetch = async <T = any>(func: () => Promise<AxiosResponse<T>>) => {
  try {
    const res = await func();
    return {
      success: true,
      data: res.data as T,
      code: res.status,
    };
  } catch (e: any) {
    console.error({e});
    return {
      success: false,
      message: "Server error",
      code: e.response.status || 500,
      error: e,
    };
  }
};

export default {
  getToken,
  getVehicle,
  getCityCode,
  getOffers,
  getOffersByNumber,
  createOffer,
  getMarkCode,
  getMarkCodeByName,
  getModelCode,
  mapGetModelCode,
  getCurrentUser,
  getUserInfo,
  manageOffer,
  sendOPT,
  confirmOPT,
  getLoginUser,
  getCities
};
