import PayPage from "./views/LiqPayPage";
import NotFoundComponent from "./views/common/NotFoundPage";
import MainPage from "./views/MainPage";
import InsurancePage from "./views/InsurancePage";
import TestPayPage from "./views/TestPayPage";
import DashboardPage from "./views/dashboard/DashboardPage";
import GreencardPage from "./views/dashboard/greencard/GreencardPage";
import EditGreencardCompany from "./views/dashboard/greencard/EditGreencardCompany";
import ViewGreencardCompany from "./views/dashboard/greencard/ViewGreencardCompany";
import ReportsPage from "./views/dashboard/reports/ReportsPage";
import PromocodesPage from "./views/dashboard/promocodes/PromocodesPage";
import SocialsPage from "./views/dashboard/social/SocialsPage";
import AddDashboardItem from "./views/dashboard/AddDashboardItem";
import EditDashboardItem from "./views/dashboard/EditDashboardItem";
import AddSocialItem from "./views/dashboard/social/AddSocialItem";
import EditSocialItem from "./views/dashboard/social/EditSocialItem";
import AddPromocodePage from "./views/dashboard/promocodes/AddPromocodePage";
import EditPromocodePage from "./views/dashboard/promocodes/EditPromocodePage";
import ViewPromocodePage from "./views/dashboard/promocodes/ViewPromocodePage";
import ViewPromocodePropertiesPage from "./views/dashboard/promocodes/ViewPromocodePropertiesPage";
import VehiclesPage from "./views/dashboard/vehicles/VehiclesPage";
import CustomersPage from "./views/dashboard/customers/CustomersPage";
import AddVehiclePage from "./views/dashboard/vehicles/AddVehiclePage";
import EditVehiclePage from "./views/dashboard/vehicles/EditVehiclePage";
import ViewVehiclePage from "./views/dashboard/vehicles/ViewVehiclePage";
import CustomersEpolicyPage from "./views/dashboard/customers/CustomersEpolicyPage";
import CustomersGreencardPage from "./views/dashboard/customers/CustomersGreencardPage";
import ManageCustomerPage from "./views/dashboard/customers/ManageCustomerPage";
import AddCustomerPage from "./views/dashboard/customers/AddCustomerPage";
import GeneralSettingsPage from "./views/dashboard/general/GeneralSettingsPage";
import AddGeneralSettingPage from "./views/dashboard/general/AddGeneralSettingPage";
import EditGeneralSettingPage from "./views/dashboard/general/EditGeneralSettingPage";
import PolicyExpiration from "./views/dashboard/expirations/PolicyExpiration";
import ReportsEpolicyPage from "./views/dashboard/reports/ReportsEpolicyPage";
import ReportsGreencardPage from "./views/dashboard/reports/ReportsGreencardPage";
import ManageReportPage from "./views/dashboard/reports/ManageReportPage";
import EditedReportPage from "./views/dashboard/reports/EditedReportPage";
import EditedGreencardCompany from "./views/dashboard/greencard/EditedGreencardCompany";
import EditedVehiclePage from "./views/dashboard/vehicles/EditedVehiclePage";
import EditedCustomerPage from "./views/dashboard/customers/EditedCustomerPage";
import EpolicyExpiration from "./views/dashboard/expirations/EpolicyExpiration";
import GreencardExpiration from "./views/dashboard/expirations/GreencardExpiration";
import PolicyNewsletter from "./views/dashboard/newsletters/PolicyNewsletter";
import PolicyNewsletterSent from "./views/dashboard/newsletters/PolicyNewsletterSent";
import AnalyticsPage from "./views/dashboard/analytics/AnalyticsPage";
import AnalyticsDetailsPage from "./views/dashboard/analytics/AnalyticsDetailsPage";

const routes: any[] = [
  {
    path: "/",
    component: MainPage,
    exact: true
  },
  {
    path: "/pay",
    component: PayPage,
    exact: true
  },
  {
    path: "/testpay/:type/:id",
    component: TestPayPage,
    exact: true
  },
  {
    path: "/:name/:id?",
    component: InsurancePage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID",
    component: DashboardPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/reports",
    component: ReportsPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/greencard",
    component: GreencardPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/greencard/edit-company/:productCode/:companyID/:greencardID",
    component: EditGreencardCompany,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/greencard/view-company/:productCode/:companyID/:greencardID",
    component: ViewGreencardCompany,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/promocodes",
    component: PromocodesPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/socials",
    component: SocialsPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/add-dashboard-item",
    component: AddDashboardItem,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/edit-dashboard-item/:itemName/:itemID",
    component: EditDashboardItem,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/add-social-item",
    component: AddSocialItem,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/edit-social-item/:socialItem/:itemID",
    component: EditSocialItem,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/add-promocode",
    component: AddPromocodePage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/edit-promocode/:promocode/:promocodeID",
    component: EditPromocodePage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/view-promocode/:promocode/:promocodeID",
    component: ViewPromocodePage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/view-promocode/:promocode/:promocodeID/properties",
    component: ViewPromocodePropertiesPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/vehicles",
    component: VehiclesPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/customers",
    component: CustomersPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/add-vehicle",
    component: AddVehiclePage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/edit-vehicle/:stateNumber/:vehicleID",
    component: EditVehiclePage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/view-vehicle/:stateNumber/:vehicleID",
    component: ViewVehiclePage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/customers/epolicy",
    component: CustomersEpolicyPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/customers/greencard",
    component: CustomersGreencardPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/customer/:insuranceType/:phoneNumber/:customerID/:action",
    component: ManageCustomerPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/add-customer/:insuranceType?",
    component: AddCustomerPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/general-settings",
    component: GeneralSettingsPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/add-general-setting",
    component: AddGeneralSettingPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/edit-general-setting/:settingID",
    component: EditGeneralSettingPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/policy-expiration",
    component: PolicyExpiration,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/reports/epolicy",
    component: ReportsEpolicyPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/reports/greencard",
    component: ReportsGreencardPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/report/:insuranceType/:phoneNumber/:vehicleNumber/:reportID/:action",
    component: ManageReportPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/report/:insuranceType/:phoneNumber/:vehicleNumber/:reportID/item/edited",
    component: EditedReportPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/greencard/company/:productCode/:companyID/:greencardID/item/edited",
    component: EditedGreencardCompany,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/vehicle/:stateNumber/:vehicleID/item/edited",
    component: EditedVehiclePage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/customer/:insuranceType/:phoneNumber/:customerID/item/edited",
    component: EditedCustomerPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/policy-expiration/epolicy",
    component: EpolicyExpiration,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/policy-expiration/greencard",
    component: GreencardExpiration,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/newsletter",
    component: PolicyNewsletter,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/:insuranceType/newsletter/email/:phoneNumber/:vehicleStateNumber",
    component: PolicyNewsletter,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/newsletter/sent",
    component: PolicyNewsletterSent,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/analytics",
    component: AnalyticsPage,
    exact: true
  },
  {
    path: "/admin/dashboard/:id/:externalID/analytics/:analyticsID/view",
    component: AnalyticsDetailsPage,
    exact: true
  },
  {
    path: "*",
    component: NotFoundComponent
  }
];

export default routes;